// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { type ReactNode, Fragment, useEffect, memo, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { type PreloadedQuery, graphql, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	isCustomFilter,
	isFilterId,
} from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { DetailsPopup } from '@atlassian/jira-issue-navigator-custom-filters/src/ui/details-popup/index.tsx';
import { DetailsButtonHeaderWrapper } from '@atlassian/jira-issue-navigator-custom-filters/src/ui/details-popup/styled.tsx';
import {
	FavoriteButton,
	FavoriteButtonHeaderWrapper,
} from '@atlassian/jira-issue-navigator-custom-filters/src/ui/favourite-button/index.tsx';
import type { CustomHeaderProps } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import PROJECT_NAME_QUERY_AGG, {
	type mainProjectNameQuery,
} from '@atlassian/jira-relay/src/__generated__/mainProjectNameQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';
import PageActions from './page-actions/index.tsx';

export const FilterTitleTooltipTag = forwardRef<HTMLSpanElement, { children?: ReactNode }>(
	(props, ref) => <FilterTitleTooltipTagStyled ref={ref} {...props} />,
);

export type Props = {
	projectKey: string;
	isQuickFiltersDisabled?: boolean;
} & CustomHeaderProps;

type ProjectBreadcrumbWithDataProps = {
	projectKey: string;
	queryReference: PreloadedQuery<mainProjectNameQuery>;
};

type BreadcrumbFallbackProps = {
	projectKey: string;
	projectName: string;
};

const ProjectBreadcrumbWithData = ({
	queryReference,
	projectKey,
}: ProjectBreadcrumbWithDataProps) => {
	const { formatMessage } = useIntl();

	const data = usePreloadedQuery<mainProjectNameQuery>(
		graphql`
			query mainProjectNameQuery($projectKey: String!, $cloudId: ID!) {
				jira {
					jiraProjectByKey(key: $projectKey, cloudId: $cloudId) {
						... on JiraProject {
							__typename
							name
						}
					}
				}
			}
		`,
		queryReference,
	);
	const projectName: string = data?.jira?.jiraProjectByKey?.name ?? '';

	return (
		<>
			<DocumentTitle
				title={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.windowTitleMigrationIssueTermRefresh
						: messages.windowTitleMigration,
					{ projectName },
				)}
			/>
			<ProjectBreadcrumb baseUrl="" projectKey={projectKey} projectName={projectName}>
				<BreadcrumbRenderer />
			</ProjectBreadcrumb>
		</>
	);
};

const BreadcrumbFallback = ({ projectKey, projectName }: BreadcrumbFallbackProps) => (
	<ProjectBreadcrumb baseUrl="" projectKey={projectKey} projectName={projectName}>
		<BreadcrumbRenderer />
	</ProjectBreadcrumb>
);

const Header = ({
	projectKey,
	jql,
	filterId,
	filterDetails,
	searchResultPageData,
	isQuickFiltersDisabled = false,
	HierarchyToggle,
	GroupByDropdown,
}: Props) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const [queryReference, loadQuery] = useQueryLoader<mainProjectNameQuery>(PROJECT_NAME_QUERY_AGG);

	const cloudId = useCloudId();

	useEffect(() => {
		loadQuery({ projectKey, cloudId });
	}, [loadQuery, projectKey, cloudId]);

	const heading = formatMessage(
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.headingIssueTermRefresh
			: messages.heading,
	);

	return (
		<>
			<PageHeader
				breadcrumbs={
					<Placeholder
						name="page-header"
						fallback={<BreadcrumbFallback projectKey={projectKey} projectName="" />}
					>
						<JSErrorBoundary
							id="ui.project-name-query.boundary"
							packageName="projectIssueNavigator"
							teamName="empanada"
							sendToPrivacyUnsafeSplunk
							attributes={isLoaderErrorAttributes}
							fallback={() => (
								<BreadcrumbFallback projectKey={projectKey} projectName={projectKey} />
							)}
						>
							{queryReference !== null && queryReference !== undefined ? (
								<ProjectBreadcrumbWithData
									queryReference={queryReference}
									projectKey={projectKey}
								/>
							) : (
								<BreadcrumbFallback projectKey={projectKey} projectName="" />
							)}
						</JSErrorBoundary>
					</Placeholder>
				}
				actions={
					<PageActions
						jql={jql}
						filterId={filterId}
						isModifiedFilter={filterDetails?.jql !== jql}
						searchResultPageData={searchResultPageData}
						isQuickFiltersDisabled={isQuickFiltersDisabled}
						HierarchyToggle={HierarchyToggle}
						GroupByDropdown={GroupByDropdown}
					/>
				}
			>
				<HeaderContentWrapper>
					{isFilterId(filterId) && filterDetails ? (
						<>
							<Tooltip content={filterDetails.name} tag={FilterTitleTooltipTag} position="mouse">
								<FilterTitleTooltipContent>{filterDetails.name}</FilterTitleTooltipContent>
							</Tooltip>
							{!isAnonymous && (
								<FavoriteButtonHeaderWrapper>
									<FavoriteButton
										filterId={filterDetails.filterId}
										isFavoriteInitial={filterDetails.isFavourite}
									/>
								</FavoriteButtonHeaderWrapper>
							)}
							{isCustomFilter(filterDetails.filterId) && filterId !== undefined && (
								<DetailsButtonHeaderWrapper>
									<DetailsPopup filterId={filterId} />
								</DetailsButtonHeaderWrapper>
							)}
						</>
					) : (
						heading
					)}
				</HeaderContentWrapper>
			</PageHeader>
		</>
	);
};

const MemoHeader = memo<Props>(Header);

export default MemoHeader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterTitleTooltipTagStyled = styled.span({
	display: 'flex',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterTitleTooltipContent = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HeaderContentWrapper = styled.span({
	display: 'inline-flex',
	alignItems: 'flex-start',
	height: '32px',
	font: token('font.heading.large'),
	maxWidth: '100%',
});
